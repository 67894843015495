@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #c5d0d3;
  border-radius: 50px;
  border: 2px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #9daeb1;
}

/* To remove autofill colors which added by some browsers */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* create new classes with tailwind classes */
@layer components {
  .card {
    @apply p-6 bg-white rounded-2xl border border-border shadow-md;
  }
  .card-header {
    @apply mb-2;
  }
}
